<template>
  <div class="container-xl">
    <Card :title="title" :subtitle="subtitle" use-back-button="true">
      <FilterBar>
        <FilterBox v-if="user_visible" label="Vendedor">
          <UserSellerSelect
            init-placeholder="Selecione um vendedor"
            v-model="search.user_id"
          />
        </FilterBox>
        <FilterBox label="Lançamentos entre">
          <DateTimePicker
            v-model="search.created_at_i"
            :with-time="true"
            :with-icon="false"
            placeholder="Inicial"
          />

          <DateTimePicker
            v-model="search.created_at_f"
            :with-time="true"
            :with-icon="false"
            placeholder="Final"
          />
        </FilterBox>
        <FilterBox label="Tipo de lançamento">
          <select v-model="search.status" class="form-control custom-select">
            <option value="">- Selecione -</option>
            <option value="O">Lançamentos de aberture</option>
            <option value="C">Lançamentos de fechamento</option>
            <option value="default">Vendas/Cancelamento</option>
            <option value="manual">Reforço/Sangria</option>
          </select>
        </FilterBox>
        <FilterBox label="Forma de pagamento">
          <select v-model="search.status" class="form-control custom-select">
            <option value="">- Selecione -</option>
            <option value="money">Dinheiro</option>
            <option value="credit">Crédito</option>
            <option value="debit">Débito</option>
          </select>
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <DeleteHeader/>
            <th data-column-id="user_id" data-visible="false">Vendedor ID</th>
            <th v-if="user_visible" data-column-id="user.name" >Vendedor</th>
            <th data-column-id="created_at" data-formatter="dt" data-width="150px">Hora</th>
            <th data-column-id="status" data-formatter="status">Tipo</th>
            <th data-column-id="type" data-formatter="type">Forma</th>
            <th data-column-id="details">Observação</th>
            <th data-column-id="amount" data-sortable="true" data-formatter="number" data-align="right">Valor</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import { Events } from '@/events'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'
import { showInfo } from '@/notifications'
import FilterBar from '@/components/FilterBar.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'
import UserSellerSelect from '@/components/UserSellerSelect.vue'
import FilterBox from '@/components/FilterBox.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  name: 'CashLog',
  components: {
    UserSellerSelect,
    DateTimePicker,
    FilterBox,
    FilterBar,
    Card,
    DeleteHeader,
    PkHeader
  },
  data () {
    return {
      cash_user_name: '',
      cash_created_at: '',
      user_visible: true,
      search: {
        type: '',
        status: '',
        user_id: '',
        cash_id: '',
        created_at_i: '',
        created_at_f: ''
      }
    }
  },
  computed: {
    title () {
      return 'Lançamentos de caixa'
    },
    subtitle () {
      if (this.cash_user_name && this.cash_created_at) {
        return `Vendedor ${this.cash_user_name} - Caixa aberto em ${this.cash_created_at}`
      }

      return ''
    }
  },
  async mounted () {
    // todo Busca no back
    if (this.$route.params.cash_user_name) {
      this.cash_user_name = this.$route.params.cash_user_name
    }
    if (this.$route.params.cash_created_at) {
      this.cash_created_at = this.$route.params.cash_created_at
    }

    if (this.$route.query.cash_id) {
      this.search.cash_id = this.$route.query.cash_id
      this.user_visible = false
    } else {
      this.user_visible = true
    }

    await this.$nextTick()
    this.init()
  },
  methods: {
    init () {
      const that = this
      const collectionObj = 'CashLog'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => this.search,
        noAddButton: true,
        actions: [],
        customMethods: {},
        formatters: {
          type (column, row) {
            const value = row[column.id]
            if (value === 'money') {
              return 'Dinheiro'
            }

            if (value === 'credit') {
              return 'Crédito'
            }

            if (value === 'debit') {
              return 'Débito'
            }

            return row[column.id]
          },
          status (column, row) {
            const value = row[column.id]
            const amount = row.amount
            if (value === 'O') {
              return 'Abertura'
            }

            if (value === 'C') {
              return 'Fechamento'
            }

            if (value === 'default') {
              if (amount < 0) return 'Cancelamento'
              return 'Venda'
            }

            if (value === 'manual') {
              if (amount < 0) return 'Sangria'
              return 'Reforço'
            }

            return row[column.id]
          }
        }
      })
    }
  }
}
</script>
