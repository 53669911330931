<template>
  <div class="container">
    <Card title="Resumo de caixa" :subtitle="subtitle" :use-back-button="true">
      <Table ref="table" size="lg" :loading="loading" :initial="initial" :empty="!rows.length" :fit-window="true">
        <HeaderRow>
          <HeaderCell width="auto">Forma de pagamento</HeaderCell>
          <HeaderCell width="50%" align="right">Valor total</HeaderCell>
        </HeaderRow>
        <tr v-for="row in rows" :key="row._id">
          <td>{{ getTypeText(row) }}</td>
          <td class="text-right">{{ formatNumber(row.aggregate) }}</td>
        </tr>
        <FooterRow>
          <FooterCell />
          <FooterCell align="right" :bold="true">{{ formatNumber(sum_amount) }}</FooterCell>
        </FooterRow>

        <template #initial>
          <div class="text-muted text-center py-5">
            <i class="la la-4x la-search"></i>
            <div class="text-muted mt-5">Acesse a listagem de caixa para visualizar os resumos</div>
          </div>
        </template>
      </Table>
    </Card>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import HeaderRow from '@/components/Table/HeaderRow.vue'
import HeaderCell from '@/components/Table/HeaderCell.vue'
import FooterRow from '@/components/Table/FooterRow.vue'
import FooterCell from '@/components/Table/FooterCell.vue'
import { H } from 'hennig-common'
import Card from '@/components/Card.vue'
import { formatDateTime, formatNumber } from '@/common'

export default {
  name: 'CashSummary',
  components: { Card, FooterCell, FooterRow, HeaderCell, HeaderRow, Table },
  data () {
    return {
      initial: true,
      loading: false,
      search: {
        cash_id: ''
      },
      rows: [],
      sum_amount: 0,
      cash_user_name: '',
      cash_created_at: ''
    }
  },
  computed: {
    title () {
      return 'Lançamentos de caixa'
    },
    subtitle () {
      if (this.cash_user_name && this.cash_created_at) {
        return `Vendedor ${this.cash_user_name} - Caixa aberto em ${this.cash_created_at}`
      }

      return ''
    }
  },
  mounted () {
    if (this.$route.query.cash_id) {
      this.load({ ...this.$route.query })
    }
  },
  methods: {
    formatNumber,
    getTypeText (row) {
      const value = row.type
      if (value === 'money') {
        return 'Dinheiro'
      }

      if (value === 'credit') {
        return 'Crédito'
      }

      if (value === 'debit') {
        return 'Débito'
      }

      return row.type || '-'
    },
    load (search) {
      this.loading = true
      H.rpc('CashLog', 'summary', [search], r => {
        if (r) {
          this.rows = r.rows || []
          this.sum_amount = r.sum_amount || 0
          this.cash_user_name = r.cash_user_name || ''
          this.cash_created_at = formatDateTime(r.cash_created_at)
        }
        this.initial = false
        this.loading = false
      })
    }
  }
}
</script>
