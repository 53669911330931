<template>
  <div class="container-xl">
    <Card :title="title" :subtitle="subtitle">
      <FilterBar>
        <FilterBox label="Vendedor">
          <UserSellerSelect
            init-placeholder="Selecione um vendedor"
            v-model="search.user_id"
          />
        </FilterBox>
        <FilterBox label="Caixas abertos entre">
          <DateTimePicker
            v-model="search.created_at_i"
            :with-time="true"
            :with-icon="false"
            placeholder="Inicial"
          />

          <DateTimePicker
            v-model="search.created_at_f"
            :with-time="true"
            :with-icon="false"
            placeholder="Final"
          />
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <DeleteHeader/>
            <StatusHeader/>

            <th
              data-align="center"
              data-column-id="cashSummary"
              data-formatter="cashSummary"
              data-width="36px"
              data-header-no-icon="true"
            ></th>

            <ContextMenuHeader/>

            <th data-column-id="user_id" data-visible="false">Vendedor ID</th>
            <th data-column-id="user.name">Vendedor</th>
            <th data-column-id="created_at" data-formatter="dt" data-width="150px">Hora</th>
            <th data-column-id="status" data-formatter="status">Status</th>
            <th data-column-id="total" data-sortable="true" data-formatter="number" data-align="right">Saldo</th>
            <th data-column-id="total_open" data-sortable="true" data-formatter="number" data-align="right">Abertura</th>
            <th data-column-id="total_close" data-sortable="true" data-formatter="number" data-align="right">Fechamento</th>

            <th
              data-align="center"
              data-column-id="access_link"
              data-formatter="access_link"
              data-width="36px"
              data-header-no-icon="true"
            ></th>
          </tr>
        </thead>
      </table>

    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'
import FilterBar from '@/components/FilterBar.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'
import UserSellerSelect from '@/components/UserSellerSelect.vue'
import FilterBox from '@/components/FilterBox.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import { formatDateTime } from '@/common'

export default {
  name: 'Cash',
  components: {
    DateTimePicker,
    FilterBox,
    UserSellerSelect,
    FilterBar,
    ContextMenuHeader,
    Card,
    StatusHeader,
    DeleteHeader,
    PkHeader
  },
  data () {
    return {
      search: {
        user_id: '',
        created_at_i: '',
        created_at_f: ''
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id', 'event_name']),
    title () {
      return 'Caixa'
    },
    subtitle () {
      return 'Controle de caixa'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const that = this
      const collectionObj = 'Cash'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => this.search,
        noAddButton: true,
        actions: [
          {
            icon: 'la-money-check',
            name: 'cashSummary',
            handler (_id, $that, $grid, getRow) {
              const data = getRow()
              that.$router.push({
                name: 'CashSummary',
                query: {
                  cash_id: _id
                },
                params: {}
              })
            }
          },
          {
            icon: 'la-angle-right',
            name: 'access_link',
            handler (_id, $that, $grid, getRow) {
              const data = getRow()
              that.$router.push({
                name: 'CashLog',
                query: {
                  cash_id: _id
                },
                params: {
                  cash_user_name: (data.user && data.user.name) || '',
                  cash_created_at: formatDateTime(data.created_at)
                }
              })
            }
          }
        ],
        customMethods: {},
        formatters: {
          mnu (column, row) {
            const items = `
                <div class="dropdown-item">
                  <i class="la la-money-bill command generateAccessCharge"></i>
                  <span class="command cashClose" data-row-id="${row._id}">Fechar o caixa</span>
                </div>

                <div class="dropdown-item">
                  <i class="la la-money-bill command duplicateProduct"></i>
                  <span class="command cashOperationRem" data-row-id="${row._id}">Sangria</span>
                </div>
`
            return `
              <div class="dropdown position-absolute">
                <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
                <div class="dropdown-menu">
                  ${items}
                </div>
              </div>`
          },
          status (column, row) {
            const value = row[column.id]
            const amount = row.amount
            if (value === 'O') {
              return 'Aberto'
            }

            if (value === 'C') {
              return 'Fechado'
            }

            return row[column.id]
          }
        }
      }).on('loaded.rs.jquery.bootgrid', function (evnt) {
        $('.la-ellipsis-v.link').dropdown()
      })
    }
  }
}
</script>
